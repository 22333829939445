import React from 'react';
import { Button } from '@material-ui/core';
import { ethers } from 'ethers';
import LoadingSpinner from "./LoadingSpinner";

const abi = require('./contract_abi.json');


// mumbai:
const CONTRACT_ADDRESS = "0x90d10ea6a33f81a94d60c47d9e1cddeb248a1867";
const RPC_URL = "https://polygon-mumbai.blockpi.network/v1/rpc/public";
const CHAIN_NAME = "Polygon MUMBAI Testnetwork"
const CURRENCY = {symbol: "MATIC", decimals: 18, name: "Matic"};

// ganache: 
//const CONTRACT_ADDRESS = "0xc8c2cfe001b68491f0e17bfe99a40f8bf806f274";
const CHAIN_ID_REQUIRED = 80001; 

var componentDidMount = false;

// Handle issues for multiple loads during develop-build
function alreadyMounted() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log("hej funkis");
        if (componentDidMount) { return true; }
        componentDidMount = true;
    }
    return false;
}

class Stupidity extends React.Component {

    constructor(props) {
        super(props);

        if (window.ethereum) {
            this.state = {
                errorMessage: null,
                address: null,
                userBalance: null,
                USDPrice: null,
                provider: new ethers.providers.Web3Provider(window.ethereum),
                signer: null,
                contract: null,
                stupidityUpdating: false
            };
        } else {
            this.state = {
                errorMessage: 'Meta mask not installed!',
                address: null,
                userBalance: null,
                USDPrice: null,
                provider: null,
                signer: null,
                contract: null,
                stupidityUpdating: false};
        }
        
        this.click_connectwallet = this.click_connectwallet.bind(this);
        this.click_increaseStupidity = this.click_increaseStupidity.bind(this);
    }

    async componentDidMount() {
        if (alreadyMounted()) { return; }

        if (window.ethereum && !this.state.contract) {
            console.log("Loading shiet")
            await this.reload();
        }
    }

    async configureChain() {
        //
        console.log("byter kedja");

        try {
            await this.state.provider.send('wallet_switchEthereumChain', [{ chainId: `0x${CHAIN_ID_REQUIRED.toString(16)}`}]);
        } catch (exception) {
            try {
                await this.state.provider.send('wallet_addEthereumChain', [{ chainId: `0x${CHAIN_ID_REQUIRED.toString(16)}`, rpcUrls: [RPC_URL], chainName: CHAIN_NAME, nativeCurrency: CURRENCY }])
            } catch (addChainError){
                console.log(addChainError);
                this.state.errorMessage = "Unable to swap chain";
                return false;
            }
        }
        try {
            await this.state.provider.send('wallet_switchEthereumChain', [{ chainId: `0x${CHAIN_ID_REQUIRED.toString(16)}` }]);
            console.log("vill ha konton");
            await this.state.provider.send("eth_requestAccounts", []);
            this.state.provider = new ethers.providers.Web3Provider(window.ethereum);
            this.state.signer = await this.state.provider.getSigner();
            this.state.contract = new ethers.Contract(CONTRACT_ADDRESS, abi, this.state.signer)
            this.state.address = await this.state.signer.getAddress();
            return true;
        } catch (exception) {
            console.log(exception);
            this.state.errorMessage = "Argh! Unable to connect.";
            return false;
        }
        

    }

    async updateBalance() {
        const address = await this.state.signer.getAddress();
        const balancexxx = await this.state.contract.balanceOf(address);
        const balance = ethers.utils.formatEther(balancexxx)
        this.setState({ userBalance: balance });
    }

    async updatePrice() {
        const pricexxx = await this.state.contract.getLatestPrice();
        console.log(pricexxx);
        const price = Number(ethers.utils.formatEther(pricexxx[0])) * 100;
        this.setState({ USDPrice: price });
    }

    async reload() {
        if(await this.configureChain()) {
            console.log("Nu  är konfig klart");
            await this.updateBalance();
            await this.updatePrice();
        }
    }

    async click_increaseStupidity(event) {
        if (event) { event.preventDefault(); }
        try {
            
            this.setState({ stupidityUpdating: true });
            const transaction = await this.state.contract.makeMeMoreStupid();
            console.log("Blev du dum:", transaction);

            await transaction.wait();
            await this.updateBalance();

        } catch (error) {
            console.log("ARGH: " + error);
            this.error("Nu gick det illa!");
        }

        this.setState({ stupidityUpdating: false });
    }

    async click_connectwallet(event) {
        if(event) { event.preventDefault(); }

        if (window.ethereum) {

            // this.state.provider.send("eth_requestAccounts", []).then(async () => {
                await this.reload();
            // })
        } else {
            this.error("Please Install Metamask!!!");
        }
    }

    error(message) {
        this.setState({ errorMessage: message });
    }

    render() {
        return (
        <div className="WalletCard">
            <img src="fish.jpg" className="App-logo" alt="logo" />
            <p>
                <Button
                        style={{ background: this.state.address ? "#A5CC82" : "white", display: this.state.address ? "none" : "inline" }}
                        onClick={this.click_connectwallet}
                >
                        {this.state.address ? "Connected!!" : "Connect"}
                </Button>
            </p>
            <p>

                <Button
                        style={{ background: this.state.address ? "#A5CC82" : "white", display: this.state.address && !this.state.stupidityUpdating ? "inline" : "none" }}
                        onClick={this.click_increaseStupidity}
                    >
                    Make me more stupid
                </Button>
            </p>
                <div style={{ display: this.state.stupidityUpdating ? "inline" : "none" }}> <LoadingSpinner/></div>
            
                <div className="displayAccount" style={{ display: this.state.address ? "inline" : "none" }}>
                    <h4 style={{ display: "none" }}  className="walletAddress">Address: {this.state.address}</h4>
                    <div className="balanceDisplay" style={{ display: this.state.userBalance ? "inline" : "none" }}  >
                    <h3>
                            Stupidity points: {this.state.userBalance}
                    </h3>
                        <p style={{ display: this.state.USDPrice ? "inline" : "none" }} > Price per point: {this.state.USDPrice} $</p>
                </div>
            </div>
                <p style={{ color: "#FF0000"}}>{this.state.errorMessage}</p>
        </div>
        )
    }
}
export default Stupidity;