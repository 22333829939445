import React from "react";
import "./spinner.css";

class LoadingSpinner extends React.Component {

    render() {
        return (
            <div className="spinner-container">
                <div className="loading-spinner">
                </div>
            </div>
        )
    }
}

export default LoadingSpinner;