import logo from './logo.svg';
import './App.css';
import Stupidity from './Stupidity';

function App() {
  return (
    <div className="App-header">
      <div className="centerCard">
        <div className="card">
          <div className="App">
            <Stupidity />
          </div>
        </div>
      </div>
    </div>
  );
}
// <WalletCard />
export default App;
